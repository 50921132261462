.rich-text-wrapper-class {
	padding: 1rem;
	border: 1px solid #ccc;
}

.rich-text-editor-class {
	min-height: 250px;
	background-color: lightgoldenrodyellow;
	padding: 1rem;
	border: 1px solid #ccc;
}

.rich-text-toolbar-class {
	border: 1px solid #ccc;
}

.rich-text-preview {
	padding: 1rem;
	margin-top: 1rem;
}