.seccion > h2 {
	margin-bottom: -10px;
}

.modificacionesParte {
	margin-top: 20px !important;
	margin-bottom: 5px;
}

.modificacionesParteWrapper {
	border: 1px solid #008da7;
	padding: 20px;
}

.modificacionesParteHeader button {
	cursor: pointer;
	float: right;
	margin-right: 15px;
	vertical-align: bottom;
}

.modificacionesParte .modificar-valor-wrapper .botones {
	text-align: right;
}

.modificacionesNombre {
	font-size: 2em;
	display: inline-block;
}

.modificacionesTipo {
	font-weight: bold;
	display: inline-block;
	letter-spacing: 1.5px;
}

.modificacionesDescripcion {
	font-style: italic;
	margin-top: -10px;
}

/* Slider */
.modificacionesParteSlider {
	padding: 0 12px;
	padding-top: 5px;
	margin-bottom: 20px;
}

.modificacionesParteSlider .slide {
	background-color: lightblue;
	padding: 10px;
	border: 1px solid black;
}

.modificacionesParteSlider .slide .slide-controls {
	text-align: right;
}

.modificacionesParteSlider .slide .slide-controls i {
	margin: 5px;
	cursor: pointer;
}

.modificacionesParteSlider .slide .noSoportado i {
	color: yellow;
}

.modificacionesParteSliderImage {
	width: 200px;
	height: 200px;
	border: 1px dashed yellow;
	margin: auto;
	margin-bottom: 5px;
	display: flex;
}

.modificacionesParteSliderImage img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	text-align: center;
	justify-content: center;
}

.nuevo .file-upload-preview-list .preview-container {
	max-width: 100%;
}

.imagen-existente {
	border-radius: 6px;
	max-width: 100%;
	max-height: 300px;
	margin-bottom: 20px;
}

.valor-modificado input,
.valor-modificado .form-control:focus {
	background-color: lightgoldenrodyellow;
}

.editar-imagen .preview-container img {
	border: 4px solid goldenrod;
}

.deleteSlide img {
	max-width: 200px;
	max-height: 200px;
	margin-bottom: 20px;
}

/* Texto y texto-corto */
.modificacionesParteTextoCorto .valor-actual,
.modificacionesParteTextoCorto .valor-actual {
	min-height: 20px;
}

/* Imagen */
.modificacionesParteImagen .valor-actual-wrapper img {
	max-width: 200px;
	max-height: 200px;
}

.modificacionesParteImagen .preview-container {
	max-width: 100% !important;
}

.modificacionesParteImagen .preview-container img {
	max-width: 200px !important;
	max-height: 200px !important;
}