/* Los overrides a los estilos del sidenav*/

.sidenav---sidenav---_2tBP {
	background-color: #fff;
    border-right: 1px solid #ddd;
}

.sidenav---sidenav-toggle---1KRjR {
	background-color: #f60;
}

.sidenav---sidenav---_2tBP .sidenavHeader {
	display: flex;
	align-items: center;
	color: white;
	height: 64px;
	background-color: #f60;
	white-space: nowrap;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenavHeader {
	display: none;
}

.sidenav---sidenav---_2tBP .sidenavLogo {
	text-align: center;
	display: block;
	padding: 15px 0;
}

.sidenav---sidenav---_2tBP .sidenavLogo img {
	max-width: 200px;
	transition: all 0.15s;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenavLogo {
	display: none;
}

.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo i {
	font-size: 1.75em;
	vertical-align: middle;
	color: #666 !important;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u {
	background-color: white;
}

.sidenav---sidenav-navitem---uwIJ- .sidenav---navtext---1AE_f {
	color: #666 !important;
}

.sidenav---sidenav-navitem---uwIJ- .sidenav---navtext---1AE_f > i {
	color: #666 !important;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f i,
.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo i {
	color: #f60 !important;
}

.sidenav---sidenav-navitem---uwIJ- .submenu-nav {
	color: #666 !important;
	position: absolute;
	right: 15px;
	top: 2px;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .submenu-nav {
	display: none;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .expand {
	display: none;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61:hover {
	display: block;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f .collapse {
	display: inline-block;
}

.sidenav---sidenav-subnavitem---1cD47 {
	left: 15px;
}

.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y {
	left: 13px;
	border-left: 2px solid #f60;
}