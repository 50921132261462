.panel-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
}

.panel-header {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	padding: 0.75rem;
	border-bottom: 1px solid #dee2e6;
	font-size: 1.25rem;
}
