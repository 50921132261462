.noticia,
.noticia .img-wrapper {
	border-radius: 0.5rem;
}

.noticia {
	display: flex;
	padding: 10px;
	margin-bottom: 10px;
	position: relative;
}

.noticia.borrador,
.color.borrador {
	background-color: #eeedd3;
}

.noticia .img-wrapper {
	display: inline-flex;
	margin-right: 10px;
	width: 80px;
	height: 80px;
	align-items: center;
	background-color: #eee;
	overflow: hidden;
}

.noticia .img-wrapper img {
	max-width: 100%;
	max-height: 100%;
}

.noticia .dropend.btn-group {
	margin-left: auto;
}

.noticia .dropdown-toggle::after {
	border: 0 !important;
	margin-left: 0 !important;
}

.noticia .dropdown button {
	align-self: center;
}

.noticia .data {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.noticia .data .titulo {
	font-size: 1.3em;
	font-weight: bold;
}

.noticia .data .etiquetas {
	color: #727599;
}

.noticia .fecha {
	position: absolute;
	bottom: 10px;
	right: 50px;
	font-size: 0.8em;
}

#noticias-leyenda {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

#noticias-leyenda .color {
	width: 20px;
	height: 20px;
}

#noticias-leyenda .texto {
	margin-left: 5px;
}

#ver-noticia label {
	font-weight: bold;
}

#ver-noticia .titulo {
	font-weight: bold;
	font-size: 1.5em;
}

#ver-noticia .copete {
	font-style: italic;
	font-size: 1.1em;
}

#ver-noticia .fecha {
	font-size: 0.8em;
}

#editar-noticia .imagen-actual img {
	max-width: 100%;
}