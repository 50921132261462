.section {
	transition: all 0.15s;
	margin-left: 64px;
}

.section.expanded {
	margin-left: 240px;
}

.no-outline {
	outline: none;
}

.table-sm td {
	vertical-align: middle;
	padding: 4px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
	color: white;
}

.tooltip >.tooltip-inner {
	background-color: rgb(236, 230, 230);
	color: rgb(63, 1, 1);
	border: 2px solid #f60;
}

.tooltip >.tooltip-arrow::before {
	border-left: 5px solid #f60;
}

body .offcanvas-end {
	width: calc(100% - 69px);
}

body.menu-expanded .offcanvas-end {
	width: calc(100% - 245px);
}

.border-primary {
	border: 1px solid var(--bs-primary);
}
