.file-upload-container {
	position: relative;
	margin: 25px 0 15px;
	border: 2px dashed lightgray;
	padding: 35px 20px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
}

.file-upload-container.dragging-over {
	background-color: #efe;
	border: 2px dashed #00C !important;
	color: #00C;
}

.file-upload-file-selector {
	font-size: 18px;
	display: block;
	width: 100%;
	border: none;
	text-transform: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
}

.file-upload-file-selector:focus {
	outline: none;
}

.file-upload-title {
	top: -21px;
	font-size: 13px;
	color: black;
	left: 0;
	position: absolute;
}

.file-upload-dragdrop-text {
	font-weight: bold;
	letter-spacing: 2.2px;
	margin-top: 0;
	text-align: center;
}

.file-upload-container button {
	transition: none;
	z-index: 1;
}

.file-upload-container.dragging-over button {
	background-color: #00c !important;
}

.file-upload-preview-container span {
	font-size: 14px;
	margin-bottom: 10px;
}

.file-upload-preview-list {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.file-upload-preview-list .preview-container {
	padding: 0.25rem;
	max-width: 30%;
	max-height: 330px;
	border-radius: 6px;
	box-sizing: border-box;
}

.file-upload-preview-list .preview-container .img-preview:hover {
	opacity: 0.55
}

.file-upload-preview-list .preview-container .img-preview {
	border-radius: 6px;
	max-width: 100%;
	max-height: 300px;
}

.file-upload-preview-list .preview-container .is-image-file {
	display: flex;
	justify-content: space-between;
}

.file-upload-preview-list .preview-container .filesize {
	margin-right: 5px;
}

.file-upload-preview-list .preview-container .file-remove {
	color: #bf0411;
	cursor: pointer;
	padding: 5px;
}

@media only screen and (max-width: 400px) {
	.file-upload-preview-list {
		flex-direction: column;
	}
}